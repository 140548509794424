/* Global Styling here */
html::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  /* background-color: #5c6571; */
  background-color: #808080;
}

::-webkit-scrollbar-track-piece {
  background-color: rgba(155, 155, 155, 0.3);
  border-radius: 20px;
}

/* Hide scrollbar for IE, Edge and Firefox */
html {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow: hidden;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

[type='date']::-webkit-inner-spin-button {
  display: none;
}
[type='date']::-webkit-calendar-picker-indicator {
  display: none;
}

.react-datepicker {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.react-datepicker .react-datepicker__header {
  border-bottom: none;
}

.react-datepicker .react-datepicker__day-name {
  font-weight: bold;
}

/* .react-datepicker .react-datepicker__year-read-view--selected-year {
  font-weight: bold;
} */

.react-datepicker .react-datepicker__navigation {
  top: 8px;
}

.react-datepicker .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  top: 3px;
}

.customDatePicker .react-datepicker {
  border: none;
}

.react-datepicker__month-container .react-datepicker__header {
  background-color: white;
}

.react-datepicker__week .react-datepicker__day--selected {
  background-color: var(--chakra-colors-gray-100);
  color: black;
  border-radius: 30px;
}
.react-datepicker__week .react-datepicker__day--selected:hover {
  background-color: var(--chakra-colors-gray-200);
  color: black;
  border-radius: 30px;
}
.react-datepicker__week .react-datepicker__day--keyboard-selected {
  background-color: var(--chakra-colors-gray-200);
  color: black;
}
.react-datepicker__week .react-datepicker__day {
  border-radius: 30px;
}
.react-datepicker__week .react-datepicker__day:hover {
  border-radius: 30px;
  background: var(--chakra-colors-gray-100);
  color: black;
}

body {
  margin: 0;
  padding: 0;
  height: 100vh;
}
body.chakra-ui-light {
  background: var(--chakra-colors-gray-50) !important;
}

body.chakra-ui-dark {
  background: var(--chakra-colors-gray-900) !important;
}

.sticky {
  position: sticky;
  position: -webkit-sticky;
}
