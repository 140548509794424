.w-md-editor-toolbar li > button > svg {
  transform: scale(1.5);
  -ms-transform: scale(1.5);
  -webkit-transform: scale(1.5);
}

.mobile-view {
  scale: 0.6 !important;
  width: calc(100vw / 0.6) !important;
  height: calc(100vh / 0.6) !important;
  margin-left: calc(50vw - calc(50vw / 0.6)) !important;
  margin-top: calc(50vh - calc(50vh / 0.6)) !important;
}

.tablet-view {
  scale: 0.8 !important;
  width: calc(100vw / 0.8) !important;
  height: calc(100vh / 0.8) !important;
  margin-left: calc(50vw - calc(50vw / 0.8)) !important;
  margin-top: calc(50vh - calc(50vh / 0.8)) !important;
}
