/* @tailwind base; */
@tailwind components;
@tailwind utilities;

.react-flow__node {
  transition-duration: 0.5s;
}
.dragging {
  transition-duration: 0s;
}
.react-flow__edge:hover {
  z-index: 999999;
}
.react-flow__edge-path-selector:hover {
  cursor: pointer;
}
.react-flow__edge-path-selector:hover + .react-flow__edge-path,
.react-flow__edge-path:hover {
  cursor: pointer;
}
.react-flow__edge-path-selector {
  fill: none;
  stroke: transparent !important;
  /* stroke: rgba(11, 12, 13, 0.23) !important; */
  stroke-width: 10;
  stroke-dasharray: none !important;
}
.react-flow__edge-path-hoverer {
  /* transition: all 0.15s ease-out; */
  fill: none;
  /* stroke: rgba(11, 12, 13, 0.23); */
  stroke: rgba(0, 0, 0, 0.5);
  /* stroke: var(--chakra-colors-red-500); */
  stroke-width: 6;
  stroke-dasharray: none !important;
}

.ReactCollapse--collapse {
  transition: height 300ms;
}

@keyframes pulse-border {
  0% {
    box-shadow: 0 0 0 0px #ffffff;
  }
  100% {
    box-shadow: 0 0 1px 6px #369c6600;
  }
}

.pulse-border {
  animation: pulse-border 1s infinite;
}
